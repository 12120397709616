import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
// UI库css公共样式文件
import './assets/css/element.scss'
import JsonExcel from "vue-json-excel";

// import 'uno.css'

Vue.component("downloadExcel", JsonExcel);


Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
