// import { removeToken } from '@/utils/auth'

// 账号 19972514919   17371453964
// 验证码  4919         3964

const state = {
  authorization: '',
  user: {
    id: '', // 用户id
    phone: '', // 手机号
    type: null, // 	账号类型（0：系统用户 1：代理商用户 2：运力公司）
    name: '', // 	用户姓名
    deptId: '', // 部门id
    deptName: '', // 所属部门
    roleId: null, // 角色id
    dataPermissionType: 0,
    status: 1, // 用户状态(0:停用 1:启用)
    agentId: null, // 代理商/运力公司id
    menuIds: null, // 当前用户所有的菜单权限
    daiWo: '0', // 待我审批
    caoWo: '0' // 抄送我审批
  }
}

const mutations = {
  SET_AUTHORIZATION: (state, authorization) => {
    state.authorization = authorization
  },
  SET_USER: (state, user) => {
    state.user = user
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { phone, code } = userInfo
    return new Promise((resolve, reject) => {
      login({ phone: phone.trim(), code })
        .then((response) => {
          if (!response) {
            reject('Verification failed, please Login again.')
            return
          }
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((response) => {
          const { data } = response
          commit('SET_USER', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_AUTHORIZATION', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
