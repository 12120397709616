<template>
  <div class="content">
    <div class="top">
      <div>后台管理系统</div>
      <div class="right">
        <div>帮助</div>
        <div>推荐使用谷歌浏览器</div>
      </div>
    </div>
    <div class="box">
      <div class="title">用户登录</div>
      <div class="li">
        <img src="../assets/img/6.png" alt="" />
        <input type="text" placeholder="请输入账号" v-model="account" />
      </div>
      <div class="li">
        <img src="../assets/img/7.png" alt="" />
        <input type="password" placeholder="请输入密码" v-model="passwd" />
      </div>
      <div style="width: 100%">
        <el-checkbox v-model="checked" size="medium">记住密码</el-checkbox>
      </div>
      <div class="but" @click="login">立即登录</div>
    </div>
  </div>
</template>

<script>
import { login } from '../api/user.js'
export default {
  data() {
    return {
      checked: false,
      account: '',
      passwd: ''
    }
  },
  created() {
    this.getLocal()
  },
  methods: {
    getLocal() {
      let checked = localStorage.getItem('checked')
      let account = localStorage.getItem('account')
      let passwd = localStorage.getItem('passwd')
      if (checked) {
        this.checked = true
        this.account = account
        this.passwd = passwd
      } else {
        this.checked = false
        this.account = ''
        this.passwd = ''
      }
    },
    async login() {
      const data = await login({
        account: this.account,
        passwd: this.passwd
      })
      if (data.code == 200) {
        localStorage.setItem('nickname', data.data.nickname)
        localStorage.setItem('avatar', data.data.avatar)
        this.$router.push('/answer/index')
        if (this.checked) {
          localStorage.setItem('checked', true)
          localStorage.setItem('account', this.account)
          localStorage.setItem('passwd', this.passwd)
        } else {
          localStorage.removeItem('checked')
          localStorage.removeItem('account')
          localStorage.removeItem('passwd')
        }
      }
      console.log(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  width: 100vw;
  background-image: url('../assets/img/5.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .top {
    padding: 30px 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 38px;
    font-weight: bold;
    .right {
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #5c5c5c;
      font-size: 22px;
      div {
        margin-left: 30px;
      }
    }
  }
  .box {
    width: 550px;
    height: 500px;
    background-color: #fff;
    position: absolute;
    right: 195px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
    box-sizing: border-box;
    .title {
      font-size: 32px;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .but {
      width: 100%;
      line-height: 60px;
      text-align: center;
      background-color: #0088ff;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      margin-top: 30px;
    }
    .li {
      width: 100%;
      height: 60px;
      background-color: #f3f4f9;
      display: flex;
      align-items: center;
      padding: 0 15px;
      box-sizing: border-box;
      margin-bottom: 30px;
      img {
        width: 30px;
        height: 32px;
        margin-right: 10px;
      }
      input {
        outline: none;
        border: none;
        background-color: #f3f4f9;
        font-size: 20px;
      }
    }
  }
}
</style>
