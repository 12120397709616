import { asyncRoutes, constantRoutes } from '@/router'

function getMenuById(id, menus) {
  for (const menu of menus) {
    if (menu.id === id) {
      return menu
    }
    if (menu.children.length > 0) {
      const childMenu = getMenuById(id, menu.children)
      if (childMenu) {
        return childMenu
      }
    }
  }
}

function filterAsyncRoutes(asyncRoutes, type, menuIds) {
  if ([1, 2].includes(type)) {
    menuIds = [1, 3, 5, 6, 7]
  }
  const filteredRoutes = asyncRoutes.map((route) => {
    if (!route.children || !route.children.length) return { ...route }
    const filteredChildren = route.children.filter((child) => {
      return menuIds.includes(Number(child.meta.id))
    })
    return { ...route, children: filteredChildren }
  })
  return filteredRoutes
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
}

const actions = {
  generateRoutes({ commit }, { type, menuIds }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getAllMenu()
        const menus = res.data
        // 按钮的权限如果存在 就把 页面id push 其中
        for (const id of menuIds) {
          const menuObj = getMenuById(id, menus)
          if (menuObj && menuObj.parentId !== null) {
            const parentId = menuObj.parentId
            if (!menuIds.includes(parentId)) {
              menuIds.push(parentId)
            }
          }
        }
        menuIds = menuIds.map((id) => Number(id))

        const accessedRoutes = filterAsyncRoutes(asyncRoutes, type, menuIds)

        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
