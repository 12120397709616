import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
// 主页面路由
import index from '../layout/index.vue'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    redirect: '/answer',
    children: [
      {
        path: '/answer',
        name: 'answer',
        redirect: '/answer/index',
        meta: {
          id: '1',
          title: '学习答题',
          url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/9fb96269d5334926a77b2a8bb613e0de15.png',
          url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/73bdfdc76bc44c8cbead0fde0d7332588.png',
        },
        component: () => import('../views/answer/index.vue'),
        children: [
          {
            path: '/answer/index',
            name: 'answerindex',
            meta: {
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/9fb96269d5334926a77b2a8bb613e0de15.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/73bdfdc76bc44c8cbead0fde0d7332588.png',
            },
            component: () => import('../views/answer/index/index.vue')
          },
          {
            path: '/answer/answerRecord',
            name: 'answerRecord',
            meta: {
              title: '答题记录',
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/9fb96269d5334926a77b2a8bb613e0de15.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/73bdfdc76bc44c8cbead0fde0d7332588.png',
            },
            component: () => import('../views/answer/answerRecord')
          },
          {
            path: '/answer/redEnvelope',
            name: 'redEnvelope',
            meta: {
              title: '红包记录',
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/9fb96269d5334926a77b2a8bb613e0de15.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/73bdfdc76bc44c8cbead0fde0d7332588.png',
            },
            component: () => import('../views/answer/redEnvelope')
          },
          {
            path: '/answer/viewingRecord',
            name: 'viewingRecord',
            meta: {
              title: '观看记录',
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/9fb96269d5334926a77b2a8bb613e0de15.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/73bdfdc76bc44c8cbead0fde0d7332588.png',
            },
            component: () => import('../views/answer/viewingRecord')
          },
        ]
      },
      {
        path: '/store',
        name: 'store',
        redirect: '/store/index',
        meta: {
          id: '2',
          title: '门店管理',
          url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/e3524880305a4590b2155d9a9532d6f29.png',
          url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/97472bb7a7bd4a59a7e37691a8bf48dd20.png'
        },
        component: () => import('../views/store/index.vue'),
        children: [
          {
            path: '/store/index',
            name: 'storeindex',
            meta: {
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/a7d373dc383b472e8497a9435c1a5f0712.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/5c9a30a7a50a4b4b99726a3d545a8da718.png'
            },
            component: () => import('../views/store/index/index.vue')
          },
          {
            path: '/store/fundDetails',
            name: 'fundDetails',
            meta: {
              title: '资金明细',
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/a7d373dc383b472e8497a9435c1a5f0712.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/5c9a30a7a50a4b4b99726a3d545a8da718.png'
            },
            component: () => import('../views/store/fundDetails')
          },
        ]
      },
      // {
      //   path: '/financial',
      //   name: 'financial',
      //   meta: {
      //     id: '3',
      //     title: '财务管理',
      //     url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/701bbb36f7634cf6979e27a3ff0bc12210.png',
      //     url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/1ce3e61bc88148c1831494240ad15c8921.png'
      //   },
      //   component: () => import('../views/financial')
      // },
      {
        path: '/money',
        name: 'money',
        meta: {
          id: '4',
          title: '门店资金',
          url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/023af6c987b046aa8ff4ee666318b43a11.png',
          url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/2f46b644e6524da8bfd986777076693d19.png'
        },
        component: () => import('../views/money'),

      },
      {
        path: '/userList',
        name: 'userList',
        meta: {
          id: '5',
          title: '用户列表',
          url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/a7d373dc383b472e8497a9435c1a5f0712.png',
          url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/5c9a30a7a50a4b4b99726a3d545a8da718.png'
        },
        component: () => import('../views/userList'),
        redirect: '/userList/index',
        children: [
          {
            path: '/userList/index',
            name: 'userListindex',
            meta: {
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/a7d373dc383b472e8497a9435c1a5f0712.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/5c9a30a7a50a4b4b99726a3d545a8da718.png'
            },
            component: () => import('../views/userList/index/index.vue')
          },
          {
            path: '/userList/answerRecord',
            name: 'answerRecord',
            meta: {
              title: '学习答题',
              url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/a7d373dc383b472e8497a9435c1a5f0712.png',
              url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/5c9a30a7a50a4b4b99726a3d545a8da718.png'
            },
            component: () => import('../views/userList/answerRecord')
          },
        ]
      },
      {
        path: '/audit',
        name: 'Audit',
        meta: {
          id: '6',
          title: '待审核用户',
          url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/a6ae2a6204024cc1b1a81c781be8b19a13.png',
          url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/d4abd89cc2de42dfa838c54883aa308f17.png'
        },
        component: () => import('../views/audit')
      },
      {
        path: '/invite',
        name: 'invite',
        meta: {
          id: '7',
          title: '邀请记录',
          url: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/6e74cb004bd846b8a848b91688e2498714.png',
          url2: 'https://plaster-mall-mp.oss-cn-shanghai.aliyuncs.com/2023/12/26/b5d28a9c1905409ebfe2c2e01d0feef216.png'
        },
        component: () => import('../views/invite')
      },
    ]
  },
  {
    path: '/404',
    component: () => import('../views/error/index.vue')
  }
]

export const asyncRoutes = [

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]



const router = new VueRouter({
  routes: constantRoutes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.path === '/') {
    next();
  } else {
    if (token) {
      next();
    } else {
      next('/');
    }
  }
});

export default router
