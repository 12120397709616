import req from '@/utils/request'

// 登录
export function login(data) {
  return req.post('/store/login', data)
}

// 获取文件上传签名参数
export function file(data) {
  return req.post('/store/file', data)
}

// 获取门店专题列表
export function getStoreSubjectList(data) {
  return req.post('/store/storeTopic/getStoreSubjectList', data)
}

// 获取门店专题活动分页
export function getStoreTopicPage(data) {
  return req.post('/store/storeTopic/getStoreTopicPage', data)
}

// 获取当前门店资金统计
export function getStoreFundsStatisticsByCurrentStore(data) {
  return req.post('/store/store/getStoreFundsStatisticsByCurrentStore', data)
}

// 获取当前门店充值分页
export function getStoreRechargePageByCurrentStore(data) {
  return req.post('/store/storeRecharge/getStoreRechargePageByCurrentStore', data)
}

// 获取当前门店客户专题活动分页
export function getUserTopicPageByCurrentStore(data) {
  return req.post('/store/userTopic/getUserTopicPageByCurrentStore', data)
}

// 门店充值(银行转账)
export function rechargeByBank(data) {
  return req.post('/store/storeRecharge/rechargeByBank', data)
}

// 获取客户列表
export function getUserPage(data) {
  return req.post('/store/user/getUserPage', data)
}

// 审核客户
export function reviewUser(data) {
  return req.post('/store/user/reviewUser', data)
}

// 更新客户
export function updateUser(data) {
  return req.post('/store/user/updateUser', data)
}

// 修改当前门店密码
export function updateCurrentPassword(data) {
  return req.post('/store/store/updateCurrentPassword', data)
}

// 修改当前门店信息
export function updateCurrentInfo(data) {
  return req.post('/store/store/updateCurrentInfo', data)
}

// 查询当前门店详情
export function getCurrentStore(data) {
  return req.post('/store/store/getCurrentStore', data)
}

// 查询子门店列表
export function getSubStorePage(data) {
  return req.post('/store/store/getSubStorePage', data)
}

// 新增子门店
export function insertSubStore(data) {
  return req.post('/store/store/insertSubStore', data)
}

// 更新子门店
export function updateSubStore(data) {
  return req.post('/store/store/updateSubStore', data)
}

// 设置门店奖励
export function updateStoreTopicReward(data) {
  return req.post('/store/storeTopic/updateStoreTopicReward', data)
}

// 获取子门店资金统计
export function getStoreFundsStatisticsBySubStore(data) {
  return req.post('/store/store/getStoreFundsStatisticsBySubStore', data)
}

// 获取子门店充值分页
export function getStoreRechargePageBySubStore(data) {
  return req.post('/store/storeRecharge/getStoreRechargePageBySubStore', data)
}

// 获取子门店客户专题活动分页
export function getUserTopicPageBySubStore(data) {
  return req.post('/store/userTopic/getUserTopicPageBySubStore', data)
}

// 获取子门店客户专题活动分页
export function batchReviewUser(data) {
  return req.post('/store/user/batchReviewUser', data)
}