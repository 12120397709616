<template>
  <div>
    <el-container>
      <el-aside width="260px">
        <div class="title">管理后台系统</div>
        <el-menu
          :default-active="active"
          router
          text-color="#333"
          active-text-color="#0088FF"
        >
          <el-menu-item
            :index="item.path"
            v-for="(item, index) in list"
            @click="changeImg(item)"
            :key="index"
          >
            <div class="aside">
              <img
                :src="item.path == selectMenu ? item.meta.url2 : item.meta.url"
                alt
              />
              <div>{{ item.meta.title }}</div>
            </div>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header>
          <div class="header">
            <div class="left">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item
                  v-for="item in pathArr"
                  :key="item.path"
                  :to="{ path: item.path }"
                  >{{ item.name }}</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
            <div class="right">
              <div class="up" @click="refreshPage">
                <div>刷新</div>
                <img
                  src="../assets/img/26.png"
                  style="width: 15px; height: 15px; margin-left: 5px"
                  alt=""
                />
              </div>
              <img :src="imgUrl" class="ava" alt="" />
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">
                    <div class="items">
                      <img src="../assets/img/23.png" alt="" />
                      <div>修改密码</div>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item command="b"
                    ><div class="items">
                      <img src="../assets/img/24.png" alt="" />
                      <div>修改资料</div>
                    </div></el-dropdown-item
                  >
                  <el-dropdown-item command="c"
                    ><div class="items">
                      <img src="../assets/img/25.png" alt="" />
                      <div>退出登录</div>
                    </div></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <el-main>
          <el-card>
            <router-view :key="key"> </router-view>
          </el-card>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="500px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧的密码" prop="oldPasswd">
          <el-input v-model="ruleForm.oldPasswd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新的密码" prop="newPasswd">
          <el-input v-model="ruleForm.newPasswd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="newPasswd1">
          <el-input v-model="ruleForm.newPasswd1" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small"
          >取消编辑</el-button
        >
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >保存数据</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="修改资料" :visible.sync="dialogVisible1" width="500px">
      <el-form
        :model="ruleForm1"
        :rules="rules1"
        ref="ruleForm1"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="客服二维码" prop="wechatImg">
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm1.wechatImg"
              :src="ruleForm1.wechatImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="客服微信号" prop="wechatNo">
          <el-input v-model="ruleForm1.wechatNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm1('ruleForm1')" size="small"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateCurrentPassword,
  updateCurrentInfo,
  file,
  getCurrentStore
} from '../api/user'
import axios from 'axios'
export default {
  computed: {
    key() {
      return this.$route.path
    }
  },
  data() {
    return {
      name: localStorage.getItem('nickname'),
      imgUrl: localStorage.getItem('avatar'),
      list: [],
      active: '/answer',
      dialogVisible: false,
      dialogVisible1: false,
      pathArr: [],
      selectMenu: '',
      ruleForm: {
        oldPasswd: '',
        newPasswd: '',
        newPasswd1: ''
      },
      rules: {
        oldPasswd: [{ required: true, message: '请输入', trigger: 'blur' }],
        newPasswd: [{ required: true, message: '请输入', trigger: 'blur' }],
        newPasswd1: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      ruleForm1: {
        wechatNo: '',
        wechatImg: ''
      },
      rules1: {
        wechatNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        wechatImg: [{ required: true, message: '请上传', trigger: 'change' }]
      }
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.caclRoute()
      },
      // 深度观察监听
      deep: true
    },
    dialogVisible(newVal) {
      if (!newVal) {
        this.ruleForm = {
          oldPasswd: '',
          newPasswd: '',
          newPasswd1: ''
        }
      }
    }
  },
  created() {
    this.list = this.$router.options.routes[1].children
    this.changeImg({ path: this.$router.currentRoute.path })
  },
  mounted() {
    this.caclRoute()
    this.active = String(this.$route.path)
  },
  methods: {
    refreshPage() {
      this.$router.go(0) // 刷新当前页面
    },
    async beforeAvatarUpload(file1) {
      const { data } = await file({
        fileName: file1.name
      })
      data.body.file = file1
      const formData = new FormData()
      for (const key in data.body) {
        formData.append(key, data.body[key])
      }
      axios({
        method: 'post',
        url: data.post,
        data: formData
      }).then((res) => {
        console.log(res)
        this.ruleForm1.wechatImg = data.url
      })
      return false
    },
    submitForm1(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = await updateCurrentInfo(this.ruleForm1)
          console.log(data)
          this.dialogVisible1 = false
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.newPasswd !== this.ruleForm.newPasswd1) {
            return this.$message({
              type: 'error',
              message: '俩次密码输入不一致'
            })
          }
          const data = await updateCurrentPassword({
            oldPasswd: this.ruleForm.oldPasswd,
            newPasswd: this.ruleForm.newPasswd
          })
          console.log(data)

          this.dialogVisible = false
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功,请重新登录'
            })
            localStorage.removeItem('token')
            this.$router.push('/p/login')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async handleCommand(e) {
      console.log(e)
      if (e == 'a') {
        this.dialogVisible = true
      } else if (e == 'b') {
        const { data } = await getCurrentStore({})
        console.log(data)
        this.ruleForm1 = {
          wechatNo: data.wechatNo,
          wechatImg: data.wechatImg
        }
        this.dialogVisible1 = true
      } else {
        this.$confirm('是否确定退出登录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            localStorage.removeItem('token')
            this.$router.push('/p/login')
          })
          .catch(() => {})
      }
    },
    // 计算路由
    caclRoute() {
      const arr = []
      this.$route.matched.forEach((item) => {
        if (item.meta && item.meta.title) {
          arr.push({
            path: item.path,
            name: item.meta.title
          })
        }
      })
      this.pathArr = arr
    },
    // 点击菜单改变图片
    changeImg(item) {
      this.selectMenu = item.path
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.el-dropdown-link {
  cursor: pointer;
  color: #333;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.title {
  line-height: 70px;
  text-align: center;
  width: 100%;
  background-color: #0088ff;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.el-breadcrumb {
  font-size: 16px !important;
  margin-left: 20px;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding-right: 50px;
  box-sizing: border-box;
  .left {
    display: flex;
    align-items: center;
    font-size: 16px;
    .imgbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 60px;
      background: #ffffff;
      border-radius: 20px 0px 0px 20px;
      box-shadow: 10px 0px 20px 1px rgba(0, 0, 0, 0.05);
      margin-right: 20px;
    }
    img {
      width: 25px;
      height: 24px;
    }
  }
  .right {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .unread {
      line-height: 30px;
      padding: 0 8px;
      border-radius: 6px;
      border: 1px solid #ee7626;
      cursor: pointer;
    }
    .line {
      width: 2px;
      height: 30px;
      background-color: #ee7626;
      margin: 0 50px;
      border-radius: 1px;
    }
    .namebox {
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 30px;
      padding: 0 8px;
      border-radius: 6px;
      border: 1px solid #ee7626;
      .name {
        font-size: 15px;
      }
    }
  }
}
.logo {
  width: 110px;
  height: 30px;
  margin: 32px 0 20px 25px;
}
.el-menu-item {
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 40px !important;
  box-sizing: border-box;
}
.aside {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 58px;
  img {
    height: 30px;
    width: 32px;
    margin-right: 8px;
  }
}
.el-header {
  background-color: #fff;
  height: 70px !important;
  padding: 0 !important;
  min-width: 1000px;
}

.el-aside {
  background-color: #fff;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.el-main {
  min-width: 1000px;
  height: calc(100vh - 70px);
  overflow: hidden;
  overflow-y: auto;
  padding: 15px !important;
  box-sizing: border-box;
}

.el-container {
  min-height: 100vh;
}
.el-menu {
  border: none !important;
}

/* 修改导航菜单栏悬浮时的背景颜色 */
.el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #edf1fc;
}
.items {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.ava {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;
}
.up {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
  color: #b1b1b1;
  cursor: pointer;
}
</style>
