import axios from 'axios'
import CryptoJS from "crypto-js";
import { Message } from 'element-ui'

axios.defaults.baseURL = '/'
axios.defaults.timeout = 30000
// 请求拦截器
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = token
  }
  return config
})

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.headers.authorization) {
      console.log(res.headers.authorization);
      localStorage.setItem("token", res.headers.authorization);
    }
    if (res.data.code === 401) {
      Message({
        type: 'error',
        message: res.data.msg
      })
      localStorage.clear()
      location.href = '/p/login'
    }
    if (res.data.code) {
      if (res.data.code !== 200 && res.data.code !== 401) {
        Message({
          type: 'error',
          message: res.data.msg
        })
      }
    }

    return res.data
  },
  (error) => {
    // console.log(error.code)
    return error
  }
)

export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          // 处理失败的请求
          console.log(err)
        })
    })
  },
  post(url, query) {
    let obj = {}
    for (let k in query) {
      if (query[k] !== '' && query[k] !== null) {
        obj[k] = query[k]
      }
    }
    obj.signKey = "ZnJhbWUtZW5jcnlwdC1rZXk=";
    const arr = [];
    for (const k in obj) {
      if (k === 'idList' || k === 'customerIds' || k === 'customerIdList' || k === 'userIdList') {
        arr.push(k + "=" + JSON.stringify(obj[k]));
      } else {
        arr.push(k + "=" + obj[k]);
      }
    }
    console.log(arr.sort().join("&"));
    let sign = CryptoJS.SHA256(arr.sort().join("&"));
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: query,
        headers: {
          sign: sign
        }
      }).then((res) => {
        resolve(res);
      })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  delete(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, { params })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          // 处理失败的请求
          console.log(err)
        })
    })
  }
}
